import React from 'react';
import './App.css';
import { Navbar, Nav, NavItem, Container, Row, Col } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// contexts
// import { ProvideAuth, useAuth } from './utils/use-auth.js';
import { useAuth } from './utils/use-auth.js';

// components
import { MPHeader, MPFooter } from './components';
import ScrollToTop from './components/ScrollToTop';

// App
import PlateList from './pages/App/PlateList.js';
import PlateDetail from './pages/App/PlateDetail.js';
import Basics from './pages/App/Basics.js';

// AdminEdit
import AdminRecipesList from './pages/Admin/RecipeList.js';
import AdminBasicsList from './pages/Admin/AdminBasicsList.js';
import AdminBasicsEdit from './pages/Admin/AdminBasicsEdit.js';
import AdminHome from './pages/Admin/AdminHome.js';
import AdminEdit from './pages/Admin/RecipeEdit.js';
import AdminUsersList from './pages/Admin/UserList.js';

// TODO- disable all console logs!
// TODO- create component for 16x9 image!
// TODO- create welcome modal
// TODO- admin reorder recipes?
// TODO- style view recipe page

function App() {
  const auth = useAuth();

  return (
    <>
      {auth.loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Router>
            <MPHeader />

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/plates/:categoryName">
                <PlateList />
              </Route>

              <Route path="/privacy">
                <Privacy />
              </Route>

              <Route path="/basics">
                <Basics />
              </Route>

              {/* <PrivateRoute> */}

              {/* ' */}

              <PrivateRoute path="/admin/basic/edit/:id">
                <AdminBasicsEdit />
              </PrivateRoute>

              <PrivateRoute path="/admin/recipes">
                <AdminRecipesList />
              </PrivateRoute>

              <PrivateRoute path="/admin/users">
                <AdminUsersList />
              </PrivateRoute>

              <PrivateRoute path="/admin/basics">
                <AdminBasicsList />
              </PrivateRoute>

              <PrivateRoute path="/admin">
                <AdminHome />
              </PrivateRoute>

              {/* <PrivateRoute path="/adminadd">
                <AdminAdd />
              </PrivateRoute> */}
              <PrivateRoute path="/adminedit/:recipeId">
                <AdminEdit />
              </PrivateRoute>
              {/* </PrivateRoute> */}

              <Route path="/recipe/:recipeId">
                <PlateDetail />
              </Route>

              {/* NEEEDS TO BE LASt!! */}
              <Route path="/">
                <PlateList />
              </Route>
            </Switch>

            <MPFooter />

            <ScrollToTop />
          </Router>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

const fakeAuth = {
  // isAuthenticated: false,
  // authenticate(cb) {
  //   fakeAuth.isAuthenticated = true;
  //   setTimeout(cb, 100); // fake async
  // },
  // signout(cb) {
  //   fakeAuth.isAuthenticated = false;
  //   setTimeout(cb, 100);
  // }
};

async function getCurrentUser() {
  console.log('step 2');
  let user = await firebase.auth();
  // let email = await user.uid;
  return user ? true : false;
}

// https://reactrouter.com/web/example/auth-workflow
function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();

  // console.log('HERE');
  // console.log(firebase.auth().user);
  let adminArray = ['joeyscarim@gmail.com', 'liana.e.epstein@gmail.com'];

  // let isAdmin;
  console.log('step 1');
  console.log(auth.user.email);

  let isAdmin = adminArray.includes(auth.user.email) ? true : false;
  // console.log(auth);
  // let isAdmin = getCurrentUser();

  // firebase.auth().onAuthStateChanged((user) => {
  //   if (user && user.email == 'joeyscarim@gmail.com') {
  //     // User logged in already or has just logged in.
  //     console.log('step 2:');
  //     console.log(user);

  //     isAdmin = true;
  //   } else {
  //     isAdmin = false;
  //     // User not logged in or has just logged out.
  //   }
  // });

  console.log('step 3');
  // alert(isAdmin);

  // setTimeout(() => {
  //   console.log('PRIVATE ROUTE!');
  //   console.log(auth);
  //   // console.log(auth.user);
  // }, 3000);

  // put a toast in here!
  !isAdmin && toast.error('Admin access only!');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function Privacy() {
  return (
    <div>
      <div className="content-bg">
        <Container bg="light">
          <Row>
            <Col>
              <div className="content">
                <h2>Privacy Policy</h2>
                <p>
                  Your privacy is important to us. It is Joseph Scarim's policy
                  to respect your privacy regarding any information we may
                  collect from you through our app, MixPlate.
                </p>
                <p>
                  We only ask for personal information when we truly need it to
                  provide a service to you. We collect it by fair and lawful
                  means, with your knowledge and consent. We also let you know
                  why we’re collecting it and how it will be used.
                </p>
                <p>
                  We only retain collected information for as long as necessary
                  to provide you with your requested service. What data we
                  store, we’ll protect within commercially acceptable means to
                  prevent loss and theft, as well as unauthorized access,
                  disclosure, copying, use or modification.
                </p>
                <p>
                  We don’t share any personally identifying information publicly
                  or with third-parties, except when required to by law.
                </p>
                <p>
                  Our app may link to external sites that are not operated by
                  us. Please be aware that we have no control over the content
                  and practices of these sites, and cannot accept responsibility
                  or liability for their respective privacy policies.
                </p>
                <p>
                  You are free to refuse our request for your personal
                  information, with the understanding that we may be unable to
                  provide you with some of your desired services.
                </p>
                <p>
                  Your continued use of our app will be regarded as acceptance
                  of our practices around privacy and personal information. If
                  you have any questions about how we handle user data and
                  personal information, feel free to contact us.
                </p>
                <p>This policy is effective as of 11 October 2020.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
