import React, { useState } from 'react';
// import logo from './logo.svg';
import './../App.css';
// import { Navbar, Nav, Modal, Button, Form } from 'react-bootstrap';
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Table,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaBars } from 'react-icons/fa';

// context providers
import { useAuth } from './../utils/use-auth.js';

// field={instructionsField}
// register={register()}
// title={'Instructions'}
// arrayName={'instructions'}
// addText={'Add instruction'}

function MPFormList({
  register,
  options,
  name,
  field,
  title,
  arrayName,
  addText,
  ...rest
}) {
  let fieldProps = {};

  if (title == 'Instructions') {
    fieldProps.as = 'textarea';
    fieldProps.rows = 2;
  } else {
    fieldProps.type = 'text';
  }

  const handleDrag = ({ source, destination }) => {
    if (destination) {
      field.move(source.index, destination.index);
    }
  };

  return (
    <>
      <Form.Label>{title}</Form.Label>
      <DragDropContext onDragEnd={handleDrag}>
        <ul>
          <Droppable droppableId="test-items">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {field.fields.map((item, index) => (
                  <Draggable
                    key={`${arrayName}[${index}]`}
                    draggableId={`item-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        key={item.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="mb-2"
                      >
                        <InputGroup>
                          {/* <Form.Control
                        type="text"
                        name={`ingredients[${index}].name`}
                        ref={register()}
                        defaultValue={item.name}
                      /> */}
                          <InputGroup.Prepend>
                            <div
                              className="btn btn-outline-primary btn-outline"
                              {...provided.dragHandleProps}
                            >
                              {/* <Button
                                variant="outline-danger"

                                //   style={{}}
                                //   onClick={() => field.remove(index)}
                              > */}
                              {/* </Button> */}
                              <FaBars />
                            </div>
                          </InputGroup.Prepend>

                          <Form.Control
                            // {type =="textarea" ? {
                            // as="textarea"
                            // rows={2}
                            {...fieldProps}
                            name={`${arrayName}[${index}].name`}
                            ref={register}
                            defaultValue={item.name}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="outline-danger"
                              onClick={() => field.remove(index)}
                            >
                              X
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </li>
                    )}
                  </Draggable>
                ))}
                <li>
                  <Button
                    onClick={() => field.append({ value: 'new tag' })}
                    variant="outline-primary"
                  >
                    {addText}
                  </Button>
                </li>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ul>
      </DragDropContext>
    </>
  );
}

export default MPFormList;
