import React from 'react';
import './../App.css';
import { Navbar, Nav, NavItem, Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

function MPFooter() {
  return (
    <>
      <footer style={{ backgroundColor: '#fff' }}>
        <Container>
          <Row style={{ padding: '20px' }}>
            <Col md>
              <img
                src={'./../logo192.png'}
                alt="logo"
                style={{ width: '40px' }}
              />
              <small class="d-block mb-3 text-muted">© MixPlate 2020</small>
            </Col>
            <Col md>
              <h5>App</h5>
              <hr></hr>
              <a
                href="https://apps.apple.com/us/app/mixplate/id1521937789"
                target="_blank"
              >
                <img
                  src={'./../assets/images/app-store.png'}
                  alt="logo"
                  style={{ width: '160px' }}
                />
              </a>
            </Col>
            <Col md>
              <h5>Pages</h5>
              <hr></hr>
              <ul class="list-unstyled text-small">
                <li>
                  <Link className="text-muted" to="/">
                    Home
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md>
              <h5>About</h5>
              <hr></hr>
              <ul class="list-unstyled text-small">
                <li>
                  <Link className="text-muted" to="/privacy">
                    Privacy
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>

      <footer class="blog-footer">
        <p>
          Built with ♥️ by <a href="https://joeyscarim.com">Joey Scarim</a>.
        </p>
      </footer>
    </>
  );
}

export default MPFooter;
