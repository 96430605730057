import React, { useState } from 'react';
import './../App.css';
import {
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Alert,
  InputGroup,
} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

// context providers
import { useAuth } from './../utils/use-auth.js';

function MPHeader() {
  // hooks
  const [showSignup, setShowSignup] = useState(false);
  const [showlogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const auth = useAuth();

  const closeAuthModals = () => {
    setShowForgot(false);
    setShowSignup(false);
    setShowLogin(false);
    setFormAlert(null);
  };

  // forgot password form handlers
  const forgotPassForm = useForm();
  const onSubmitForgotPass = (data) => {
    setFormAlert(null);
    auth
      .sendPasswordResetEmail(data.email)
      .then((result) => {
        setFormAlert({
          type: 'success',
          message:
            'Check your email for a password reset link. Please allow up to 5 minutes for this.',
        });
      })
      .catch((error) => {
        if (error.code == 'auth/user-not-found')
          setFormAlert({
            type: 'danger',
            message: 'Email not found. Try again.',
          });
        else setFormAlert({ type: 'danger', message: 'Error. Try again.' });
      });
  };

  // forgot password form handlers
  const signupForm = useForm();
  const onSubmitSignup = (data) => {
    setFormAlert(null);
    auth
      .signup(data.email, data.password)
      .then((result) => {
        closeAuthModals();
        toast.success('Welcome to MixPlate!');
      })
      .catch((error) => {
        if (error.code == 'auth/email-already-in-use')
          setFormAlert({
            type: 'danger',
            message: 'There is already a MixPlate account with that email.',
          });
        else setFormAlert({ type: 'danger', message: 'Error. Try again.' });
      });
  };

  const loginForm = useForm();
  const onSubmit = (data) => {
    auth
      .signin(data.email, data.password)
      .then((result) => {
        closeAuthModals();
        toast.success('Login successful');
      })
      .catch((error) => {
        console.log(error);
        if (error.code == 'auth/wrong-password')
          setFormAlert({
            type: 'danger',
            message: 'Error. Incorrect password.',
          });
        else if (error.code == 'auth/user-not-found')
          setFormAlert({
            type: 'danger',
            message: 'Error. User not found.',
          });
        else if (error.code == 'auth/too-many-requests')
          setFormAlert({
            type: 'danger',
            message: 'Error. Too many requests. Try again later.',
          });
        else setFormAlert({ type: 'danger', message: 'Error. Try again.' });
      });
  };

  const onLogout = (data) => {
    auth.signout().then((result) => toast.success('Logout successful'));
  };

  return (
    <>
      <Navbar
        // bg="light"
        style={{
          backgroundColor: '#fff !important',
          boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
        }}
        variant="primary"
        expand="lg"
      >
        <Link to="/">
          <Navbar.Brand>
            <img src="/assets/images/icon.png" width="50" />
            <div
              style={{
                fontSize: '1.5rem',
                marginLeft: '3px',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              <strong>Mix</strong>Plate
            </div>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {!auth.user ? (
              <>
                {/* <Nav.Link to="/basics">Basics</Nav.Link> */}
                <Link to="/basics" className="nav-link">
                  Basics
                </Link>
                <Nav.Link onClick={() => setShowLogin(true)}>Login</Nav.Link>
                <Nav.Link onClick={() => setShowSignup(true)}>Signup</Nav.Link>
              </>
            ) : (
              <>
                {/* <Link to="/" >
                  Plates
                </Link> */}
                <NavLink
                  to="/plates"
                  className="nav-link"
                  activeStyle={{
                    fontWeight: 'bold',
                    // color: 'red',
                  }}
                >
                  Plates
                </NavLink>
                <Link to="/myplates" className="nav-link">
                  My Plates
                </Link>
                {/* <Link to="/basics" className="nav-link">
                  Basics
                </Link> */}
                <NavLink
                  to="/basics"
                  className="nav-link"
                  activeStyle={{
                    fontWeight: 'bold',
                    // color: 'red',
                  }}
                >
                  Basics
                </NavLink>
                {/* <Nav.Link>Basics</Nav.Link>
                </Link> */}

                <Nav.Link onClick={onLogout}>Logout</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Forgot password modal */}
      <Modal show={showForgot} onHide={closeAuthModals}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={forgotPassForm.handleSubmit(onSubmitForgotPass)}
          >
            {formAlert && (
              <Alert variant={formAlert.type}>{formAlert.message}</Alert>
            )}

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                ref={forgotPassForm.register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email must be a valid format',
                  },
                })}
                isInvalid={forgotPassForm.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {forgotPassForm.errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" size="lg" block>
              Email reset link
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Login modal */}
      <Modal show={showlogin} onHide={closeAuthModals}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={loginForm.handleSubmit(onSubmit)}>
            {formAlert && (
              <Alert variant={formAlert.type}>{formAlert.message}</Alert>
            )}

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email address"
                name="email"
                ref={loginForm.register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email must be a valid format',
                  },
                })}
                isInvalid={loginForm.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {loginForm.errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                ref={loginForm.register({ required: true, minLength: 6 })}
                isInvalid={loginForm.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                Password must be 6+ characters
              </Form.Control.Feedback>
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <a
                  className="btn btn-link"
                  href
                  style={{ fontSize: '14px' }}
                  onClick={() => {
                    closeAuthModals();
                    setShowForgot(true);
                  }}
                >
                  Forgot password?
                </a>
              </div>
            </Form.Group>

            <Button variant="primary" type="submit" size="lg" block>
              Login
            </Button>

            <div
              style={{
                textAlign: 'center',
                paddingTop: '10px',
              }}
            >
              <a
                className="btn btn-link"
                href
                onClick={() => {
                  closeAuthModals();
                  setShowSignup(true);
                }}
              >
                Need an account? Signup →
              </a>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Signup modal */}
      <Modal show={showSignup} onHide={closeAuthModals}>
        <Modal.Header closeButton>
          <Modal.Title>Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={signupForm.handleSubmit(onSubmitSignup)}>
            {formAlert && (
              <Alert variant={formAlert.type}>{formAlert.message}</Alert>
            )}

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                ref={signupForm.register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email must be a valid format',
                  },
                })}
                isInvalid={signupForm.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {signupForm.errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref={signupForm.register({ required: true, minLength: 6 })}
                  isInvalid={signupForm.errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  Password must be 6+ characters
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Button variant="primary" type="submit" size="lg" block>
              Signup
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MPHeader;
