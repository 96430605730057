import React, { useState, useEffect } from 'react';

import './../../App.css';
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Form,
  // Card,
} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import firebase from 'firebase/app';

import { MPHeader, MPFooter } from '../../components';

function PlateDetail() {
  const [data, setData] = useState(null);
  let match = useRouteMatch();
  let { recipeId } = useParams();

  const getColor = (index) => {
    if (index == 0) return '#005322';
    else if (index == 1) return '#008a35';
    else return '#00bc45';
  };

  useEffect(() => {
    // alert(recipeId);

    let query = firebase.firestore().collection('recipes').doc(recipeId);

    query.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      //   let docs = querySnapshot.docs;
      //   let array = [];

      //   for (let doc of docs) {
      //     array.push(doc.data());
      //   }
      setData(querySnapshot.data());
    });
  }, []);

  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      {/* <p>Recipe page</p> */}
      <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
        {/* <Row> */}
        {!data ? (
          <p>Loading...</p>
        ) : (
          <div>
            <Row>
              <Col sm={8}>
                <h1>{data.title}</h1>

                {data.tags.map((item, index) => (
                  <div
                    style={{
                      // backgroundColor: getColor(index),
                      // backgroundColor: 'rgb(0, 188, 70)',
                      backgroundColor: 'rgb(0 138 53)',
                      // backgroundImage:
                      //   'linear-gradient(to right, rgb(0, 139, 53), rgb(0, 188, 70))',
                      // display: flex;
                      // width: 'fit-content',
                      display: 'inline-block',
                      margin: '3px',
                      fontWeight: 'bold',
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '30px',
                    }}
                  >
                    {item}
                  </div>
                ))}

                <hr />
                <Alert variant={'primary'}>{data.descriptor}</Alert>

                <h3>Ingredients</h3>
                <table class="table table-striped recipe-table">
                  <tbody>
                    {data.ingredients.map((item) => (
                      <tr>
                        <td>
                          • {item}
                          {/* <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label={item} />
                          </Form.Group> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <h3>Instructions</h3>
                <table class="table table-striped recipe-table">
                  <tbody>
                    {data.instructions.map((item, index) => (
                      <tr>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <div
                              style={{
                                fontSize: 24,
                                color: 'green',
                                fontWeight: 'bold',
                                margin: '10px',
                                marginLeft: 0,
                                marginTop: 0,
                                // display: 'inline',
                              }}
                            >
                              {index + 1}.
                            </div>
                            <div>{item}</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* <h3>Instructions</h3>
                <ol className="instructions-list">
                  {data.instructions.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol> */}
              </Col>

              <Col sm={4}>
                <Card
                  style={{
                    marginBottom: '30px',
                    // boxShadow: '5px 5px 5px -5px rgba(191,191,191,.5)',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px',
                    cursor: 'pointer',
                    border: 'none',
                  }}
                  // onClick={() => history.push('/recipe/' + recipe.id)}
                >
                  <div
                    class="image-container"
                    style={{
                      borderRadius: '0.25rem',
                      borderBottomRightRadius: '0',
                      borderBottomLeftRadius: '0',
                      backgroundImage: 'url(' + data.image + ')',
                    }}
                  ></div>

                  <Card.Body>
                    {/* <Card.Title style={{ maxLines: '3' }}>
                      {recipe.title}
                    </Card.Title>
                    <Card.Text>{recipe.descriptor}</Card.Text> */}

                    <Row>
                      <Col>
                        <p style={{ textAlign: 'center' }}>
                          <strong>PREP</strong>
                          <br />
                          {data.prep_time || '-'}
                        </p>

                        {/* <p>PREP</p>

                        <p>{</p> */}
                      </Col>
                      <Col>
                        <p style={{ textAlign: 'center' }}>
                          <strong>COOK</strong>
                          <br />
                          {data.cook_time || '-'}
                        </p>
                      </Col>
                      <Col>
                        <p style={{ textAlign: 'center' }}>
                          <strong>SERVES</strong>
                          <br />
                          {data.serves || '-'}
                        </p>
                        {/* <p></p> */}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* <img style={{ width: '100%' }} src={data.image} /> */}

                {/* {data.mixes[0]} */}
                {data.mixes && (
                  <>
                    <Card
                      style={{
                        marginBottom: '30px',
                        // boxShadow: '5px 5px 5px -5px rgba(191,191,191,.5)',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px',
                        cursor: 'pointer',
                        border: 'none',
                      }}
                      // onClick={() => history.push('/recipe/' + recipe.id)}
                    >
                      <Card.Body>
                        <h3>Mixes</h3>
                        <ul style={{ marginLeft: '-1rem' }}>
                          {data.mixes.map((item) => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      </Card.Body>
                    </Card>
                  </>
                )}

                {data.matches && (
                  <>
                    {/* <h3>Matches</h3>
                    <ul>
                      {data.matches.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul> */}

                    <Card
                      style={{
                        marginBottom: '30px',
                        // boxShadow: '5px 5px 5px -5px rgba(191,191,191,.5)',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px',
                        cursor: 'pointer',
                        border: 'none',
                      }}
                      // onClick={() => history.push('/recipe/' + recipe.id)}
                    >
                      <Card.Body>
                        <h3>Matches</h3>
                        <ul style={{ marginLeft: '-1rem' }}>
                          {data.matches.map((item) => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </Col>
            </Row>
          </div>
        )}
        {/* </Row> */}
      </Container>
    </div>
  );
}

export default PlateDetail;
