// import React from 'react';
import React, { useState, useEffect } from 'react';

import './../../App.css';
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Table,
  Form,
  InputGroup,
} from 'react-bootstrap';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
// import firebase, { registerVersion } from 'firebase';
import firebase from 'firebase/app';

import 'firebase/storage';

import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { useCollection, useDocument } from '@nandorojo/swr-firestore';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { MPHeader, MPFooter, MPFormList } from '../../components';

const storage = firebase.storage();

// TODO - make these there own components!!!
export function Input({ register, name, ...rest }) {
  return <input name={name} ref={register} {...rest} />;
}

export function Select({ register, options, name, ...rest }) {
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Label>{rest.title}</Form.Label>
      <Form.Control as="select" custom name={name} ref={register} {...rest}>
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

function AdminRecipeEdit() {
  const { data, update, error, add } = useCollection(`recipes`);
  const [isLoaded, setLoaded] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  let { recipeId } = useParams();

  // get the current recipe
  const currentRecipe = useDocument('recipes/' + recipeId);

  const { register, handleSubmit, watch, errors, control, reset } = useForm({
    defaultValues: {
      title: 'test',
      lastName: 'luo',
      email: 'bluebill1049@hotmail.com',
    },
  });

  const tagsField = useFieldArray({
    control,
    name: 'tags',
  });

  const instructionsField = useFieldArray({
    control,
    name: 'instructions',
  });

  const ingredientsField = useFieldArray({
    control,
    name: 'ingredients',
  });

  const mixesField = useFieldArray({
    control,
    name: 'mixes',
  });

  const matchesField = useFieldArray({
    control,
    name: 'matches',
  });

  useEffect(() => {
    // alert(recipeId);
    if (!isLoaded && currentRecipe.data) {
      setLoaded(true);
      let recipeData = currentRecipe.data;
      console.log(recipeData);
      if (recipeData.tags)
        recipeData.tags = recipeData.tags.map((item) => ({
          name: item,
        }));
      recipeData.ingredients = recipeData.ingredients.map((item) => ({
        name: item,
      }));
      recipeData.instructions = recipeData.instructions.map((item) => ({
        name: item,
      }));
      if (recipeData.mixes)
        recipeData.mixes = recipeData.mixes.map((item) => ({
          name: item,
        }));
      if (recipeData.matches)
        recipeData.matches = recipeData.matches.map((item) => ({
          name: item,
        }));
      setImagePreview(recipeData.image);

      reset(recipeData);
    }
  }, [currentRecipe]);

  const onChange = (e) => {
    // alert('changed!');
    const image = e.target.files[0];

    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // this.setState({ progress });
      },
      (error) => {
        // Error function ...
        console.log(error);
      },
      () => {
        // complete function ...
        storage
          .ref('images')
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            // this.setState({ url });
            // alert(url);
            setImagePreview(url);
          });
      }
    );
    // };
  };

  const onSubmit = (data) => {
    // theTags =
    console.log(data);

    if (imagePreview) data.image = imagePreview;

    // flat map the tags
    data.tags = data.tags ? data.tags.flatMap((x) => x.name) : [];
    data.ingredients = data.ingredients
      ? data.ingredients.flatMap((x) => x.name)
      : [];
    data.instructions = data.instructions
      ? data.instructions.flatMap((x) => x.name)
      : [];
    data.mixes = data.mixes ? data.mixes.flatMap((x) => x.name) : [];
    data.matches = data.matches ? data.matches.flatMap((x) => x.name) : [];
    console.log(data);

    // add(data);
    currentRecipe.update(data);

    // alert('added!!');
    toast.success('Recipe updated!');
  };

  if (error) return <div>Error!</div>;
  if (!data) return <div>Loading...</div>;

  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <Alert variant={'dark'} style={{ marginBottom: '15px' }}>
          💡 Tips:
          <ul>
            <li>
              <strong>Press the Update button first</strong> to make sure the
              editor is working smoothly.
            </li>
            <li>
              If it doesn't work,{' '}
              <strong>try a hard refresh (SHIFT + CMD + R)</strong>.
            </li>
            <li>
              <strong>Save often!</strong> (press update every couple mins)
            </li>

            <li>
              Images should be <strong>1600x900 px, jpg (no HEIC!)</strong>
            </li>
          </ul>
          {/* <strong>Learn what MP is and why we made it.</strong> */}
        </Alert>

        <h3 className="mb-5">
          Editing{' '}
          <u>{currentRecipe.data ? currentRecipe.data.title : 'Loading..'}</u>
        </h3>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <u>
                <h5 className="mb-4">Overview</h5>
              </u>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Image</Form.Label>
                <img src={imagePreview} style={{ width: '100%' }} />
                <input
                  // ref={register}
                  type="file"
                  // name="imageUpload"
                  onChange={onChange}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  // placeholder="Enter email"
                  name="title"
                  ref={register}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Live</Form.Label>
                <Form.Check
                  type={'checkbox'}
                  // id={`default-${type}`}
                  label={`Published (uncheck for draft mode)`}
                  name="live"
                  ref={register}
                />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="descriptor"
                  ref={register}
                />
              </Form.Group>

              <Select
                title="Category"
                name="category"
                options={[
                  'mains',
                  'soups',
                  'breads',
                  'breakfast',
                  'sweets',
                  'sides',
                ]}
                register={register()}
              />

              <Row>
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Prep Time</Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder="Password"
                      name="prep_time"
                      ref={register({ required: true })}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Cook Time</Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder="Password"
                      name="cook_time"
                      ref={register({ required: true })}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Select
                    title="Serves"
                    name="serves"
                    options={['1', '2', '4', '6', '8', '10']}
                    register={register()}
                  />
                </Col>
              </Row>

              <Form.Label>Tags</Form.Label>

              <ul>
                {tagsField.fields.map((item, index) => (
                  <li key={item.id}>
                    <InputGroup className="mb-2">
                      <Form.Control
                        type="text"
                        name={`tags[${index}].name`}
                        ref={register()}
                        defaultValue={item.name}
                      />
                      <InputGroup.Append>
                        <Button
                          variant="outline-danger"
                          onClick={() => tagsField.remove(index)}
                        >
                          X
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </li>
                ))}
                <li>
                  <Button
                    onClick={() => tagsField.append({ value: 'new tag' })}
                    variant="outline-primary"
                  >
                    Add tag
                  </Button>
                </li>
              </ul>

              <u>
                <h5 className="mb-4">Mixes & Matches</h5>
              </u>

              <Form.Label>Mixes</Form.Label>
              <ul>
                {mixesField.fields.map((item, index) => (
                  <li key={item.id}>
                    <InputGroup className="mb-2">
                      <Form.Control
                        type="text"
                        name={`mixes[${index}].name`}
                        ref={register()}
                        defaultValue={item.name}
                      />
                      <InputGroup.Append>
                        <Button
                          variant="outline-danger"
                          onClick={() => mixesField.remove(index)}
                        >
                          X
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </li>
                ))}
                <li>
                  <Button
                    onClick={() => mixesField.append({ value: 'new tag' })}
                    variant="outline-primary"
                  >
                    Add mix
                  </Button>
                </li>
              </ul>

              <Form.Label>Matches</Form.Label>
              <ul>
                {matchesField.fields.map((item, index) => (
                  <li key={item.id}>
                    <InputGroup className="mb-2">
                      <Form.Control
                        type="text"
                        name={`matches[${index}].name`}
                        ref={register()}
                        defaultValue={item.name}
                      />
                      <InputGroup.Append>
                        <Button
                          variant="outline-danger"
                          onClick={() => matchesField.remove(index)}
                        >
                          X
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </li>
                ))}
                <li>
                  <Button
                    onClick={() => matchesField.append({ value: 'new tag' })}
                    variant="outline-primary"
                  >
                    Add match
                  </Button>
                </li>
              </ul>
            </Col>
            <Col>
              {/* {errors.exampleRequired && <span>This field is required</span>} */}

              <u>
                <h5 className="mb-4">Recipe</h5>
              </u>

              <MPFormList
                field={ingredientsField}
                register={register()}
                title={'Ingredients'}
                arrayName={'ingredients'}
                addText={'Add ingredient'}
                // fieldArray={tagsField}
              />

              {/* title: instructions
                field: instructionsField
                register  */}
              <MPFormList
                field={instructionsField}
                register={register()}
                title={'Instructions'}
                arrayName={'instructions'}
                addText={'Add instruction'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

export default AdminRecipeEdit;
