import React, { useState, useEffect } from 'react';

import './../../App.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {
  useHistory,
  BrowserRouter as Router,
  useParams,
} from 'react-router-dom';

import { MPHeader, MPFooter } from '../../components';
import { useCollection } from '@nandorojo/swr-firestore';
import { useAuth } from '../../utils/use-auth.js';
import useWindowDimensions from '../../utils/useWindowDimensions.js';

function PlateList() {
  // hooks
  const history = useHistory();
  const { height, width } = useWindowDimensions();

  // url params
  let { categoryName } = useParams();
  if (!categoryName) categoryName = 'all';

  // get recipes
  const { data } = useCollection('recipes', {
    where:
      categoryName && categoryName != 'all'
        ? [
            ['live', '==', true],
            ['category', '==', categoryName],
          ]
        : ['live', '==', true],
    // where: ['live', '==', false],
    // limit: 10,
    // orderBy: ['age', 'desc'],
    listen: true,
  });

  return (
    <div style={{ backgroundColor: 'rgb(250,250,250)' }}>
      <div
        style={{
          backgroundColor: 'green',
          padding: '10px',
          textAlign: 'center',
          color: '#fff',
          backgroundImage: 'linear-gradient(to right, #008b35 , #00bc46)',
          fontWeight: '500',
          boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
        }}
      >
        Welcome to MixPlate!
      </div>
      <Container style={{ paddingTop: '25px', paddingBottom: '50px' }}>
        <Row>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: width > 600 ? 'center' : 'left',
              marginBottom: '25px',

              overflow: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {[
              { title: 'All', emoji: '🍽', value: 'all' },
              { title: 'Mains', emoji: '🥘', value: 'mains' },
              { title: 'Soups', emoji: '🥣', value: 'soups' },
              { title: 'Breads', emoji: '🥖', value: 'breads' },
              { title: 'Breakfast', emoji: '🍳', value: 'breakfast' },
              { title: 'Sweets', emoji: '🍪', value: 'sweets' },
              { title: 'Sides', emoji: '🍟', value: 'sides' },
            ].map((item, index) => (
              <div style={{ margin: 10, pointerEvents: 'auto' }}>
                <div
                  onClick={() => history.push('/plates/' + item.value)}
                  style={{
                    borderColor:
                      item.value != categoryName ? '#FAFAFA' : '#059b3a',
                    borderWidth: 3,
                    borderStyle: 'solid',

                    width: 65,
                    height: 65,
                    backgroundColor: 'white',
                    cursor: 'pointer',

                    borderRadius: 100,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,

                    elevation: 5,
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px',
                  }}
                >
                  <div
                    style={{ padding: 5, fontSize: 32, textAlign: 'center' }}
                  >
                    {item.emoji}
                  </div>
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: 12,
                    fontWeight: '400',
                    marginTop: 5,
                  }}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </Row>

        <Row>
          {!data ? (
            <div class="loading-area">
              <p>Loading...</p>
            </div>
          ) : (
            data.map((recipe) => (
              <Col sm={6} md={4} lg={4}>
                <Card
                  style={{
                    marginBottom: '30px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px',
                    cursor: 'pointer',
                    border: 'none',
                  }}
                  onClick={() => history.push('/recipe/' + recipe.id)}
                >
                  <div
                    class="image-container"
                    style={{
                      borderRadius: '0.25rem',
                      borderBottomRightRadius: '0',
                      borderBottomLeftRadius: '0',
                      backgroundImage: 'url(' + recipe.image + ')',
                    }}
                  ></div>

                  <Card.Body>
                    <Card.Title style={{ maxLines: '3' }}>
                      {recipe.title}
                    </Card.Title>
                    <Card.Text>{recipe.descriptor}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Container>
    </div>
  );
}

export default PlateList;
