import React, { useState, useEffect } from 'react';
import './../../App.css';
import { Container, Row, Col, Alert, ListGroup, Table } from 'react-bootstrap';
import { MPHeader, MPFooter } from './../../components';
import { useCollection } from '@nandorojo/swr-firestore';

function Basics() {
  // hooks, default state
  const [listIndex, setListIndex] = useState(0);
  const [content, setContent] = useState(null);

  const { data } = useCollection('basics', {
    where: ['live', '==', true],
    listen: true,
  });

  // on list menu press
  const setBasic = (index, data) => {
    setListIndex(index);
    setContent(data);
  };

  if (!data) return <Row>loading..</Row>;

  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <Alert variant={'dark'} style={{ marginBottom: '15px' }}>
          💡 Welcome to MixPlate Basics! The Basics are a set of common cooking
          references all in one, uniform spot.
        </Alert>

        <Row>
          <Col>
            <h2>Basics</h2>
            <hr></hr>
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            <ListGroup>
              {data.map((b, i) => (
                <ListGroup.Item
                  action
                  active={listIndex == i}
                  onClick={() => setBasic(i, b.content)}
                >
                  {b.title}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col sm={8}>
            <div
              className="embedContent"
              dangerouslySetInnerHTML={{ __html: content || data[0].content }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Basics;
