import React, { useState, useEffect } from 'react';
import './../../App.css';
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';

import {
  useCollection,
  useDocument,
  deleteDocument,
} from '@nandorojo/swr-firestore';
import { useForm } from 'react-hook-form';

import { MPHeader, MPFooter } from '../../components';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

function AdminRecipeList() {
  const history = useHistory();

  const [showAddModal, setShowAddModal] = useState(false);

  const { data, update, error, add } = useCollection(`recipes`);

  const deleteRecipe = (id) => {
    var r = window.confirm('Are you suree?');
    if (r == true) {
      deleteDocument('recipes/' + id);
    }
  };

  const forgotPassForm = useForm();
  const onSubmitForgotPass = (data) => {
    let recipe = {
      title: data.title,
      descriptor: '',
      live: false,
      tags: [],
      mixes: [],
      matches: [],
      ingredients: [],
      instructions: [],
      serves: '',
      cook_time: '',
      prep_time: '',
    };

    // add(recipe).then((result) => console.log(result));
    firebase
      .firestore()
      .collection('recipes')
      .add(recipe)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id);

        history.push('/adminedit/' + docRef.id);
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });

    console.log(recipe);
  };

  if (error) return <div>Error!</div>;
  if (!data) return <div>Loading...</div>;

  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <Alert variant={'dark'} style={{ marginBottom: '30px' }}>
          💡 Welcome admin. This dashboard lets you view, add, edit, and delete
          recipes. Enjoy!
        </Alert>

        {/* <Link className="float-right mb-4" to="/adminadd"> */}
        <Button
          className="float-right mb-4"
          onClick={() => setShowAddModal(true)}
          variant="primary"
        >
          Add New Recipe
        </Button>
        {/* </Link> */}

        <h3>All Recipes</h3>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Category</th>
              <th>Live</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((recipe, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{recipe.title}</td>
                <td>{recipe.category}</td>
                <td>{recipe.live ? '✅' : '❌'}</td>
                <td>
                  <div>
                    <Button
                      variant="danger"
                      className="float-right "
                      onClick={() => deleteRecipe(recipe.id)}
                    >
                      Delete
                    </Button>

                    <Link
                      className="float-right mr-2"
                      to={'/adminedit/' + recipe.id}
                    >
                      <Button variant="primary">Edit</Button>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      {/* add modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Recipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={forgotPassForm.handleSubmit(onSubmitForgotPass)}
          >
            {/* {formAlert && (
              <Alert variant={formAlert.type}>{formAlert.message}</Alert>
            )} */}
            <Alert variant="dark">
              💡 The option to scrape from a URL will live here too.
            </Alert>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                // placeholder="Enter email"
                name="title"
                ref={forgotPassForm.register({
                  required: 'Required',
                })}
                isInvalid={forgotPassForm.errors.title}
              />
              <Form.Control.Feedback type="invalid">
                Title is required
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" size="lg" block>
              Create recipe
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AdminRecipeList;
