import React, { useState, useEffect } from 'react';
import './../../App.css';
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import { FaUserAlt, FaBreadSlice, FaBook, FaMailBulk } from 'react-icons/fa';

import {
  useCollection,
  useDocument,
  deleteDocument,
} from '@nandorojo/swr-firestore';
import { useForm } from 'react-hook-form';

import { MPHeader, MPFooter } from './../../components';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

function AdminHome() {
  const history = useHistory();

  const [showAddModal, setShowAddModal] = useState(false);

  const { data, update, error, add } = useCollection(`recipes`);

  const deleteRecipe = (id) => {
    var r = window.confirm('Are you suree?');
    if (r == true) {
      deleteDocument('recipes/' + id);
    }
  };

  const forgotPassForm = useForm();
  const onSubmitForgotPass = (data) => {
    let recipe = {
      title: data.title,
      descriptor: '',
      live: false,
      tags: [],
      mixes: [],
      matches: [],
      ingredients: [],
      instructions: [],
      serves: '',
      cook_time: '',
      prep_time: '',
    };

    // add(recipe).then((result) => console.log(result));
    firebase
      .firestore()
      .collection('recipes')
      .add(recipe)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id);

        history.push('/adminedit/' + docRef.id);
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });

    console.log(recipe);
  };

  if (error) return <div>Error!</div>;
  if (!data) return <div>Loading...</div>;

  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <Alert variant={'dark'} style={{ marginBottom: '30px' }}>
          💡 Welcome admin.
        </Alert>

        {/* </Link> */}

        <h3>Admin Dashboard</h3>

        <Row>
          <Col>
            <Card
              onClick={() => history.push('/admin/recipes')}
              style={{
                marginBottom: '30px',
                boxShadow: '5px 5px 5px -5px rgba(191,191,191,.5)',
                cursor: 'pointer',
              }}
              className="text-center"
            >
              {/* <Card.Header>Featured</Card.Header> */}
              <Card.Body>
                <h1>
                  <FaBreadSlice />
                </h1>
                <Card.Title>Recipes</Card.Title>
                <Card.Text>View & edit recipes</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
              {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
            </Card>
          </Col>

          <Col>
            <Card
              onClick={() => history.push('/admin/basics')}
              style={{
                marginBottom: '30px',
                boxShadow: '5px 5px 5px -5px rgba(191,191,191,.5)',
                cursor: 'pointer',
              }}
              className="text-center"
            >
              {/* <Card.Header>Featured</Card.Header> */}
              <Card.Body>
                <h1>
                  <FaBook />
                </h1>
                <Card.Title>Basics</Card.Title>
                <Card.Text>View & edit basics</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card
              onClick={() => history.push('/admin/users')}
              style={{
                marginBottom: '30px',
                boxShadow: '5px 5px 5px -5px rgba(191,191,191,.5)',
                cursor: 'pointer',
              }}
              className="text-center"
            >
              {/* <Card.Header>Featured</Card.Header> */}
              <Card.Body>
                <h1>
                  <FaUserAlt />
                </h1>
                <Card.Title>Users</Card.Title>
                <Card.Text>View & edit users</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card
              // onClick={() => history.push('/admin/basics')}
              style={{
                marginBottom: '30px',
                boxShadow: '5px 5px 5px -5px rgba(191,191,191,.5)',
                cursor: 'pointer',
              }}
              className="text-center"
            >
              {/* <Card.Header>Featured</Card.Header> */}
              <Card.Body>
                <h1>
                  <FaMailBulk />
                </h1>
                <Card.Title>Push Notifications</Card.Title>
                <Card.Text>Coming soon!</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>

      {/* add modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Recipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={forgotPassForm.handleSubmit(onSubmitForgotPass)}
          >
            {/* {formAlert && (
              <Alert variant={formAlert.type}>{formAlert.message}</Alert>
            )} */}
            <Alert variant="dark">
              💡 The option to scrape from a URL will live here too.
            </Alert>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                // placeholder="Enter email"
                name="title"
                ref={forgotPassForm.register({
                  required: 'Required',
                })}
                isInvalid={forgotPassForm.errors.title}
              />
              <Form.Control.Feedback type="invalid">
                Title is required
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" size="lg" block>
              Create recipe
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AdminHome;
