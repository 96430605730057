// import React from 'react';
import React, { useState, useEffect } from 'react';

import './../../App.css';
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Table,
  Form,
  InputGroup,
} from 'react-bootstrap';

import { Editor } from '@tinymce/tinymce-react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
// import firebase, { registerVersion } from 'firebase';
import firebase from 'firebase/app';

import 'firebase/storage';

import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { useCollection, useDocument } from '@nandorojo/swr-firestore';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { MPHeader, MPFooter, MPFormList } from './../../components';

const storage = firebase.storage();

// TODO - make these there own components!!!
export function Input({ register, name, ...rest }) {
  return <input name={name} ref={register} {...rest} />;
}

export function Select({ register, options, name, ...rest }) {
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Label>{rest.title}</Form.Label>
      <Form.Control as="select" custom name={name} ref={register} {...rest}>
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

function AdminBasicsEdit() {
  //   const { data, update, error, add } = useCollection(`recipes`);
  const [isLoaded, setLoaded] = useState(false);
  const [contentArea, setContentArea] = useState('');

  let { id } = useParams();

  // get the current recipe
  const currentRecipe = useDocument('basics/' + id);

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    // alert(recipeId);
    if (!isLoaded && currentRecipe.data) {
      setLoaded(true);
      let recipeData = currentRecipe.data;
      // console.log(recipeData);
      setContentArea(recipeData.content);

      reset(recipeData);
    }
  }, [currentRecipe]);

  const onSubmit = (data) => {
    console.log(data);

    data.content = contentArea;
    currentRecipe.update(data);

    toast.success('Basic updated!');
  };

  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
    setContentArea(content);
  };

  if (currentRecipe.error) return <div>Error!</div>;
  if (!currentRecipe.data) return <div>Loading...</div>;

  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <Alert variant={'dark'} style={{ marginBottom: '15px' }}>
          💡 Tips:
          <ul>
            <li>
              <strong>Press the Update button first</strong> to make sure the
              editor is working smoothly.
            </li>
          </ul>
          {/* <strong>Learn what MP is and why we made it.</strong> */}
        </Alert>

        <h3 className="mb-5">
          Editing{' '}
          <u>{currentRecipe.data ? currentRecipe.data.title : 'Loading..'}</u>
        </h3>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  // placeholder="Enter email"
                  name="title"
                  ref={register}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Live</Form.Label>
                <Form.Check
                  type={'checkbox'}
                  // id={`default-${type}`}
                  label={`Published (uncheck for draft mode)`}
                  name="live"
                  ref={register}
                />
              </Form.Group>

              <Editor
                apiKey="amqe3sqn9pexrfihd87acdiy71c44tdwaggtk9lx268gak9o"
                initialValue={currentRecipe.data.content}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo |  h1 h3 bold | \
                    alignleft aligncenter | table | \
                    bullist numlist',
                }}
                onEditorChange={handleEditorChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

export default AdminBasicsEdit;
