import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
import 'firebase/firestore';
import 'firebase/auth';
// import { FirebaseAppProvider } from 'reactfire';
import { Fuego, FuegoProvider } from '@nandorojo/swr-firestore';
import { ProvideAuth, useAuth } from './utils/use-auth.js';

const firebaseConfig = {
  /* Paste your config object from Firebase console here */
  apiKey: 'AIzaSyAVDDEZ89mQQw_UshUsKA6RdHJQVrbFtc0',
  authDomain: 'spotted-kitchen.firebaseapp.com',
  databaseURL: 'https://spotted-kitchen.firebaseio.com',
  projectId: 'spotted-kitchen',
  storageBucket: 'spotted-kitchen.appspot.com',
  messagingSenderId: '250370754177',
  appId: '1:250370754177:web:9cc106700994677dc1af80',
  measurementId: 'G-D80P4DT5G1',
};

const fuego = new Fuego(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <FuegoProvider fuego={fuego}>
      {/* <FirebaseAppProvider firebaseConfig={firebaseConfig}> */}
      <ProvideAuth>
        <App />
      </ProvideAuth>
      {/* </FirebaseAppProvider> */}
    </FuegoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
